import logo from './logo.svg';
import './App.css';
import AppRouter from './shared/AppRouter';

function App() {
  return (
    <AppRouter>

    </AppRouter>
  );
}

export default App;
